import { compose } from 'redux';
import React, { useState } from 'react';
import { graphql } from 'react-apollo';
import { withStyles } from '@mui/styles';
import {
  Button,
  IconButton,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import {
  AddCircleOutline as AddIcon,
  Delete as DeleteIcon,
  FilterNone as DuplicateIcon,
  ArrowForward as ArrowIcon,
} from '@mui/icons-material';
import MultiDepartmentSelect from './MultiDepartmentSelect';
import ProjectTemplateWorkflowLevel from './ProjectTemplateWorkflowLevel';
import ProjectTemplateWorkflowApproversSelector from './ProjectTemplateWorkflowApproversSelector';
import * as Queries from 'common/apollo/queries';
import withRouteHelpers from 'common/hoc/withRouteHelpers';
import * as colors from 'common/components/CnCUi/theme/colors';

const StyledSelect = styled(Select)({
  backgroundColor: '#F4F4F4',
});

const StyledComboSelect = styled(Select)({
  backgroundColor: '#F4F4F4',
  width: 200,
});

const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: 20,
});

const StyledButton = styled(Button)({
  textTransform: 'Capitalize',
  border: `1px solid #0000FF`,
  color: '#0000FF',
  marginLeft: 'auto',
  '&:hover': {
    border: '1px solid #1A55FD',
  },
  borderRadius: 0,
  height: 30,
});

const ErrorTypography = styled(Typography)({
  color: 'red',
  fontSize: 12,
  marginLeft: 'auto',
});

const styles = theme => ({
  row: {
    display: 'flex',
    marginBottom: theme.spacing(4),
    width: '100%',
  },
  rowIndex: {
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: 'x-large',
    width: 40,
    height: 40,
    color: '#0000B3',
    border: '1px solid #0000B3',
    borderRadius: 20,
    padding: '9px 0 0 0',
    marginRight: theme.spacing(2),
    flexShrink: 0,
  },
  rowBody: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    background: '#E2F1FF',
    padding: theme.spacing(2),
    overflowX: 'scroll',
  },
  rowControls: {
    paddingBottom: theme.spacing(2),
    display: 'flex',
  },
  rowLevels: {
    display: 'flex',
    height: 340,
  },
  conditionSelect: {
    width: 300,
    display: 'inline-block',
    marginRight: theme.spacing(2),
  },
  outlinedButton: {
    borderRadius: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: theme.spacing(1.7),
  },
  containedButton: {
    borderRadius: 0,
    fontSize: theme.spacing(1.7),
    textTransform: 'Capitalize',
    color: '#FFFFFF',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    background: '#0000FF',
    '&:hover': {
      background: '#1A55FD',
    },
    '&:active': {
      background: '#0000B3',
    },
  },
  addLevelContainer: {
    paddingTop: 60,
    border: '2px dashed grey',
    width: 250,
    height: '100%',
    textAlign: 'center',
    background: 'white',
    flexShrink: 0,
  },
  deleteWorkflowContainer: {
    alignSelf: 'center',
    padding: theme.spacing(0, 2, 0, 2),
  },
  arrow: {
    alignSelf: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: colors.GRAY,
  },
});

const ProjectTemplateWorkflow = props => {
  const {
    classes,
    departments,
    index,
    isTemplateI9,
    onChange,
    onDelete,
    onDuplicate,
    templateRoles,
    templateRules,
    workflow,
  } = props;
  const {
    id,
    departments: workflowDepartments,
    rule,
    levels,
    overscaleConditionTriggered,
    type,
  } = workflow;
  const ruleGroupOptions = templateRules.map(({ id, name }) => ({
    value: id,
    label: name,
    type: 'rule',
  }));
  const departmentGroupOptions = departments.map(({ id, name }) => ({
    value: id,
    label: name,
    type: 'department',
  }));
  const rateValue = overscaleConditionTriggered;
  const ruleValue = rule?.id;

  const ruleMenuItems = ruleGroupOptions.map(({ value, label }) => (
    <MenuItem key={value} value={value}>
      {label}
    </MenuItem>
  ));

  const departmentsConditionSelectOptions = [
    { label: 'Departments', options: departmentGroupOptions },
  ];
  const conditionSelectValues =
    (type === 'combo' && 'combo') ||
    (rule && rule.id) ||
    (type === 'department' && 'department') ||
    (overscaleConditionTriggered && 'overscale_rate') ||
    'default';
  const selectedDepartments = workflowDepartments?.map(department => ({
    value: department.id,
    label: department.name,
  }));
  const [isApproversDialogOpen, setIsApproversDialogOpen] = useState(false);
  const isDepartmentsOpen = conditionSelectValues === 'department';
  const isComboCondition = conditionSelectValues === 'combo';

  const getNewLevelName = () => {
    if (!levels.length) return 'Level 1';
    const lastLevel = levels[levels.length - 1];
    const lastLevelNumber =
      parseInt((/Level ([0-9])+/.exec(lastLevel.name) || [])[0]) ||
      levels.length;
    return `Level ${lastLevelNumber + 1}`;
  };

  const addLevel = approvers => {
    const newLevel = {
      name: getNewLevelName(),
      role: null,
      users: approvers,
    };
    onChange({ ...workflow, levels: levels.concat(newLevel) });
  };

  const updateLevel = (index, updatedLevel) => {
    const newLevels = levels.map((level, i) =>
      i === index ? updatedLevel : level,
    );
    onChange({ ...workflow, levels: newLevels });
  };

  const deleteLevel = index => {
    const newLevels = levels.filter((__, i) => i !== index);
    onChange({ ...workflow, levels: newLevels });
  };

  const updateSelectCondition = event => {
    const value = event.target.value;
    if (value === 'default') {
      onChange({
        ...workflow,
        rule: null,
        departments: [],
        overscaleConditionTriggered: false,
        type: 'default',
      });
    } else if (value === 'overscale_rate') {
      onChange({
        ...workflow,
        rule: null,
        departments: [],
        overscaleConditionTriggered: true,
        type: 'overscale_rate',
      });
    } else if (value === 'department') {
      onChange({
        ...workflow,
        rule: null,
        departments: [],
        overscaleConditionTriggered: false,
        type: 'department',
      });
    } else if (value === 'combo') {
      onChange({
        ...workflow,
        rule: null,
        departments: [],
        overscaleConditionTriggered: false,
        type: 'combo',
      });
    } else {
      const selectedRule = templateRules.find(rule => rule.id === value);
      onChange({
        ...workflow,
        rule: selectedRule,
        departments: [],
        overscaleConditionTriggered: false,
        type: 'workflow',
      });
    }
  };

  const updateSelectedDepartmentIds = departments => {
    onChange({ ...workflow, departments });
  };

  const updateComboRate = event => {
    const value = event.target.value;
    onChange({
      ...workflow,
      overscaleConditionTriggered: value,
    });
  };

  const updateComboRule = event => {
    const value = event.target.value;
    if (value === '') {
      onChange({
        ...workflow,
        rule: null,
      });
      return;
    }
    const selectedRule = templateRules.find(rule => rule.id === value);
    onChange({
      ...workflow,
      rule: selectedRule,
    });
  };

  let comboError = false;
  if (type === 'combo') {
    let selectedCount = 0;
    if (departments.length > 0) selectedCount++;
    if (rule) selectedCount++;
    if (overscaleConditionTriggered) selectedCount++;
    if (selectedCount < 2) {
      comboError = true;
    }
  }

  return (
    <div
      className={classes.row}
      data-test-id={`ProjectTemplateWorkflow-root-${id}`}
    >
      <div
        className={classes.rowIndex}
        data-test-id="ProjectTemplateWorkflow-index"
      >
        {index + 1}
      </div>
      <div className={classes.rowBody}>
        <div className={classes.rowControls}>
          <span data-test-id="ProjectTemplateWorkflow-condition">
            <Typography variant="h6">Condition</Typography>
            <StyledSelect
              className={classes.conditionSelect}
              value={conditionSelectValues}
              variant="filled"
              onChange={updateSelectCondition}
            >
              <MenuItem value="default">Default</MenuItem>
              <MenuItem value="overscale_rate">Overscale Rate</MenuItem>
              <MenuItem value="department">Departments</MenuItem>
              <MenuItem value="combo">Combo</MenuItem>
              {ruleMenuItems}
            </StyledSelect>
          </span>
          {(isDepartmentsOpen || isComboCondition) && (
            <span data-test-id="ProjectTemplateWorkflow-departmentsCondition">
              <Typography variant="h6">Departments</Typography>
              <MultiDepartmentSelect
                options={departmentsConditionSelectOptions}
                className={classes.conditionSelect}
                selectedDepartments={selectedDepartments}
                onChange={updateSelectedDepartmentIds}
              />
            </span>
          )}
          {isComboCondition && (
            <>
              <span data-test-id="ProjectTemplateWorkflow-rateCondition">
                <Typography variant="h6">Rate</Typography>
                <StyledComboSelect
                  className={classes.conditionSelect}
                  value={rateValue}
                  variant="filled"
                  onChange={updateComboRate}
                >
                  <MenuItem value={false}>None</MenuItem>
                  <MenuItem value={true}>Overscale Rate</MenuItem>
                </StyledComboSelect>
              </span>
              <span data-test-id="ProjectTemplateWorkflow-ruleCondition">
                <Typography variant="h6">Rule</Typography>
                <StyledComboSelect
                  className={classes.conditionSelect}
                  value={ruleValue}
                  variant="filled"
                  onChange={updateComboRule}
                >
                  <MenuItem value="">None</MenuItem>
                  {ruleMenuItems}
                </StyledComboSelect>
              </span>
            </>
          )}
          <StyledContainer>
            <StyledButton
              variant="outlined"
              data-test-id="ProjectTemplateWorkflow-duplicate"
              onClick={onDuplicate}
              className={classes.outlinedButton}
              startIcon={<DuplicateIcon />}
            >
              Duplicate
            </StyledButton>
            {comboError && (
              <ErrorTypography color="error" variant="body1">
                Must select at least 2
              </ErrorTypography>
            )}
          </StyledContainer>
        </div>
        <div className={classes.rowLevels}>
          {levels.map((level, index) => (
            <React.Fragment>
              <ProjectTemplateWorkflowLevel
                level={level}
                index={index}
                key={index}
                onChange={updatedLevel => updateLevel(index, updatedLevel)}
                onDelete={() => deleteLevel(index)}
                templateRoles={templateRoles}
                isTemplateI9={isTemplateI9}
              />
              <ArrowIcon fontSize="large" className={classes.arrow} />
            </React.Fragment>
          ))}
          <div className={classes.addLevelContainer}>
            <Button
              variant="contained"
              data-test-id="ProjectTemplateWorkflow-addLevel"
              onClick={() => setIsApproversDialogOpen(true)}
              className={classes.containedButton}
              startIcon={<AddIcon />}
            >
              Add Level
            </Button>
            {isApproversDialogOpen && (
              <ProjectTemplateWorkflowApproversSelector
                isTemplateI9={isTemplateI9}
                open={isApproversDialogOpen}
                onClose={() => setIsApproversDialogOpen(false)}
                onSubmit={addLevel}
              />
            )}
          </div>
          <div className={classes.deleteWorkflowContainer}>
            <IconButton
              onClick={onDelete}
              data-test-id="ProjectTemplateWorkflow-delete"
            >
              <DeleteIcon fontSize="large" />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const withDepartments = graphql(Queries.GetDepartments, {
  options: ({ projectId }) => ({
    variables: { projectId },
  }),
  props: ({ data: { departments = [] } = {} }) => ({
    departments,
  }),
});

export default compose(
  withRouteHelpers,
  withDepartments,
  withStyles(styles),
)(ProjectTemplateWorkflow);
